<template>
  <layout>
    <template slot="header">
      About program
    </template>
    <template>
      <form-subheader title="Application" />
      <form-item-value title="PixelMonitor version" :value="localVersion" />
      <form-item-value title="PixelCore version" :value="version" />
      <form-item-value title="PixelCore timezone" :value="timezone" />
      <form-subheader title="About us" />
      <form-item-value title="PIXEL NETWORKS LIMITED" />
      <form-item-value title="https://pixel-networks.com/" />
    </template>

    <template slot="footer">
      <v-spacer />
      <v-btn color="primary" text @click.stop="$emit('close')">
        OK
      </v-btn>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault';
import { onMounted } from 'vue-demi';
import { getVersion, getTimezone } from '@/modules/about/getVersion';
import { ref } from '@vue/composition-api';
import appVersion from 'raw-loader!../../../VERSION';

export default {
  name: 'About',
  components: {
    Layout
  },
  setup() {
    let version = ref('Loading...');
    let timezone = ref('Loading...');
    let localVersion = ref('Loading...');

    onMounted(() => {
      localVersion.value = appVersion;

      getVersion().then(res => {
        version.value = res.short;
      });
      getTimezone().then(res => {
        timezone.value = res;
      });
    });

    return {
      version,
      timezone,
      localVersion
    };
  }
};
</script>

<style lang="sass"></style>
